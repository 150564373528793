/* eslint space-before-function-paren: ["error", "always"] */
/* eslint no-param-reassign: ["error", { "props": false }] */
import Vue from 'vue'
import Toasted from 'vue-toasted'

import * as mutations from './types/mutations-types'
import * as actions from './types/actions-types'

import {
    getDefaultCity,
    fetchOptionsFilters,
    fetchPriceRange,
    fetchQuestions,
    fetchCities,
    fetchVariants,
    fetchParameters,
    calculateVariants,
    createOrder,
    createAuthRequest,
    submitAuth,
    getLastUpdate,
    fetchDeliveryIntervals,
    fetchPromoCode,
    fetchDeliveryPrice,
    fetchBonusSystem,
    fetchShopContacts,
    fetchPickupPoints,
} from '../api/shop'
import { UPDATE_HTTP_IN_PROGRESS } from './types/mutations-types'
import createOptionsForDaData, { DA_DATA_URL } from '../utils/createOptionsForDaData'

Vue.use(Toasted)

export default {
    [actions.LOAD_OPTIONS_FILTERS] ({ commit }) {
        fetchOptionsFilters().then(
            options => commit(mutations.SET_OPTIONS_VARIANTS, options),
        )
    },

    [actions.LOAD_PRICE_RANGE] ({ commit }) {
        fetchPriceRange().then(
            range => commit(mutations.SET_PRICE_RANGE, range),
        )
    },

    [actions.LOAD_QUESTIONS] ({ commit }) {
        commit(mutations.CLEAR_CUSTOMER_ANSWERS)
        fetchQuestions().then(
            questions => commit(mutations.SET_QUESTIONS, questions),
        )
    },

    [actions.LOAD_CITIES] ({ state, commit }) {
        fetchCities()
            .then(cities => commit(mutations.SET_CITIES, cities.map(city => city.name)))
            .then(() => getDefaultCity())
            .then(defaultCity => {
                if (!state.customer.city) {
                    commit(mutations.UPDATE_CUSTOMER_CITY, {
                        city: defaultCity || state.cities[0],
                        exact: !!defaultCity,
                    })
                }
            })
    },

    [actions.LOAD_BONUS_SYSTEM] ({ commit }) {
        fetchBonusSystem()
            .then(bonusSystem => commit(mutations.SET_BONUS_SYSTEM, bonusSystem))
    },

    [actions.LOAD_SHOP_CONTACTS] ({ commit }) {
        fetchShopContacts()
            .then(contacts => commit(mutations.SET_CONTACTS, contacts))
    },

    [actions.LOAD_DELIVERY_PRICES] ({ state, commit }, isDeliveryPriceForCustomer) {
        commit(mutations.UPDATE_HTTP_IN_PROGRESS, true)
        fetchDeliveryPrice(state.daData.address)
            .then(
                deliveryPrices => {
                    isDeliveryPriceForCustomer ? commit(mutations.SET_DELIVERY_PRICES, deliveryPrices) : commit(mutations.SET_DELIVERY_PRICES_TEST, deliveryPrices)
                   commit(mutations.UPDATE_HTTP_IN_PROGRESS, false)
                }
            ).catch(() => commit(mutations.UPDATE_HTTP_IN_PROGRESS, false)
        )
    },

    [actions.LOAD_STREET_DA_DATA] ({ state, commit }) {
        fetch(DA_DATA_URL, createOptionsForDaData(state, Vue.prototype.tokenDaData))
            .then(response => response.json())
            .then(result => {
                commit(mutations.SET_LOADER_ADDRESSES, result)
            })
    },

    [actions.CHOOSE_ADDRESS_COURIER] ({ state, commit }) {
        commit(mutations.UPDATE_CUSTOMER_DELIVERY_TYPE, state.daData.deliveryTypeLocal)
        commit(mutations.UPDATE_CUSTOMER_ADDRESS_HOUSE, state.daData.address.house)
        commit(mutations.UPDATE_CUSTOMER_ADDRESS_STREET, state.daData.address.street)
        commit(mutations.UPDATE_CUSTOMER_ADDRESS_CITY, state.daData.address.city)
        commit(mutations.UPDATE_CUSTOMER_ADDRESS_FULL_STREET, state.daData.address.street + ', ' + state.daData.address.house)
        commit(mutations.UPDATE_CUSTOMER_CHOOSE_ADDRESS, true)
    },

    [actions.CHOOSE_ADDRESS_PICKUP] ({ state, commit }, isPageCreateOrder) {
        commit(mutations.CLEAR_CUSTOMER_ADDRESS)
        commit(mutations.UPDATE_CUSTOMER_DELIVERY_TYPE, state.daData.deliveryTypeLocal)
        commit(mutations.UPDATE_DA_DATA_ADDRESS_FULL_STREET, '')
        if (isPageCreateOrder) {
            commit(mutations.UPDATE_CUSTOMER_ADDRESS_FULL_STREET, state.customer.delivery.pickupPoint.name)
        } else {
            commit(mutations.UPDATE_CUSTOMER_DELIVERY_PICKUP_POINT, state.selectPickupPoint)
            commit(mutations.UPDATE_CUSTOMER_ADDRESS_FULL_STREET, state.selectPickupPoint.name)
        }
        commit(mutations.SET_DELIVERY_PRICES, null)
        commit(mutations.TOGGLE_ADDRESS_IS_CHOSEN, true)
    },

    [actions.CHOOSE_PICKUP_POINT_ON_MAP] ({state,commit}, id) {
        commit(mutations.UPDATE_CUSTOMER_DELIVERY_PICKUP_POINT, state.pickupPoints.find((pickupPoint) => pickupPoint.apiId === id))
    },

    [actions.LOAD_DELIVERY_INTERVALS] ({ commit }) {
        fetchDeliveryIntervals()
            .then(deliveryIntervals => {
                commit(mutations.SET_DELIVERY_INTERVALS, deliveryIntervals)
                commit(mutations.UPDATE_CUSTOMER_DELIVERY_DATE, deliveryIntervals[0][0].date)
            })
    },

    [actions.LOAD_PICKUP_POINTS] ({ commit }) {
        fetchPickupPoints()
            .then(pickupPoints => commit(mutations.SET_PICKUP_POINTS, pickupPoints))
    },

    [actions.REQUEST_AUTH_CODE] ({ state, commit }) {
        commit(UPDATE_HTTP_IN_PROGRESS, true)
        createAuthRequest(state.authenticatorData.phone)
            .then(result => {
                commit(mutations.SET_AUTH_PHONE_REQUEST, result)
                commit(mutations.CHANGE_TIME_BLOCK, 60)
                commit(UPDATE_HTTP_IN_PROGRESS, false)
            })
            .catch(result => {
                commit(mutations.SET_AUTH_PHONE_REQUEST, null)
                commit(UPDATE_HTTP_IN_PROGRESS, false)
            })
    },

    [actions.CHECK_CODE] ({ state, commit }) {
        commit(UPDATE_HTTP_IN_PROGRESS, true)
        submitAuth(state.authenticatorData.phone, state.authenticatorData.code)
            .then(auth => {
                commit(mutations.SET_AUTH_DATA, auth)
                window.location.href = Routing.generate('homepage');
                commit(UPDATE_HTTP_IN_PROGRESS, false)
            })
            .catch(result => {
                commit(mutations.SET_AUTH_DATA, null)
                commit(UPDATE_HTTP_IN_PROGRESS, false)
            })
    },

    [actions.LOAD_PROMOCODE] ({ state, commit }) {
        fetchPromoCode(state.promoCode.name)
            .then(promoCode => commit(mutations.SET_PROMOCODE, promoCode))
            .catch(() => commit(mutations.SET_ERROR_PROMOCODE))
    },

    [actions.LOAD_VARIANTS] ({ commit }, productId) {
        commit(mutations.SET_CURRENT_VARIANTS, [])
        commit(mutations.SET_PARAMETERS_LENGTH, 0)
        commit(mutations.SET_PARAMETERS_VARIANTS, [])

        fetchVariants(productId)
            .then(variants => {
                commit(mutations.SET_CURRENT_VARIANTS, variants)
                return fetchParameters(productId)
            })
            .then(parameters => {
                commit(mutations.SET_PARAMETERS_VARIANTS, parameters)
                commit(mutations.SET_PARAMETERS_LENGTH, parameters.length)
            })
    },

    [actions.ADD_PRODUCT] ({ state, commit }) {
        Vue.toasted.show('Товар добавлен в корзину', { duration: 1000 })
        calculateVariants(state.draftProduct).then(
            variants => variants.forEach(variant => commit(mutations.ADD_PRODUCT, variant)),
        )
    },

    [actions.CREATE_ORDER] ({ state, commit }) {
        state.customer.delivery.unixTime = new Date(state.customer.delivery.date).getTime() / 1000
        const { customer, products } = state

        commit(mutations.UPDATE_HTTP_IN_PROGRESS, true)
        createOrder({ customer, products }).then(
            order => {
                commit(mutations.UPDATE_CUSTOMER_LAST_ORDER_ID, order.orderId)
                commit(mutations.CLEAR_PRODUCTS)
                commit(mutations.CLEAR_CUSTOMER_ANSWERS)
                commit(mutations.UPDATE_CUSTOMER_FILE, {})
                commit(mutations.UPDATE_CUSTOMER_MESSAGE, '')
                commit(mutations.UPDATE_CUSTOMER_ALLOW_PERSONAL_DATA_PROCESSING, false)
                commit(mutations.UPDATE_HTTP_IN_PROGRESS, false)
            },
            () => commit(mutations.UPDATE_HTTP_IN_PROGRESS, false),
        )
    },

    [actions.SEND_FEEDBACK] ({ state, commit }) {
        const { customer } = state

        Vue.toasted.show('Обращение принято. Мы свяжемся с вами.', { duration: 5000 })
        commit(mutations.UPDATE_HTTP_IN_PROGRESS, true)
        createOrder({ customer }).then(
            () => {
                commit(mutations.UPDATE_CUSTOMER_FILE, {})
                commit(mutations.UPDATE_CUSTOMER_MESSAGE, '')
                commit(mutations.UPDATE_HTTP_IN_PROGRESS, false)
            },
            () => commit(mutations.UPDATE_HTTP_IN_PROGRESS, false),
        )
    },

    [actions.CLEAR_PRODUCTS_ON_CATALOG_UPDATE] ({ commit }) {
        const STATE_CLEANER_KEY = 'qeep-state-cleaner'
        getLastUpdate().then(lastUpdated => {
            const needReload = localStorage.getItem(STATE_CLEANER_KEY) !== lastUpdated
            if (needReload) {
                commit(mutations.CLEAR_PRODUCTS_AND_DRAFT_PRODUCTS)
                localStorage.setItem(STATE_CLEANER_KEY, lastUpdated)
            }
        })
    },

    // eslint-disable-next-line object-curly-newline
    [actions.CHANGE_DRAFT_QUANTITY] ({ commit, id, delta, price }) {
        commit(
            mutations.CHANGE_DRAFT_PRODUCT_QUANTITY,
            {
                id,
                delta,
                price: parseInt(price, 10),
            },
        )
    },

    // eslint-disable-next-line object-curly-newline
    [actions.CHANGE_DISCRIMINATED_QUANTITY] ({ commit, id, delta, price, discriminator }) {
        commit(
            mutations.CHANGE_DRAFT_DISCRIMINATED_QUANTITY,
            {
                id,
                delta,
                price: parseInt(price, 10),
                discriminator,
            },
        )
    },
}
